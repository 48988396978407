import React from "react";
import Topbar from '../core/TopNav';
import Footer from "../core/Footer";
//import ChatBox from "./chat/ChatBox";


const Live = () => {
  
    return (
      <div>
        <div>
      <Topbar />
      </div>
      <div>
         
       <div style={{display: 'flex', marginTop:'7em' }}>
       <div style={{width:"85%", height:"0", position:"relative", paddingBottom:"56.25%"}} ><iframe title="videoplayer" src="https://player.onestream.live/embed?token=MjA1MjEy&type=up" style={{position:"absolute", width:"100%", height:"100%", overflow: "hidden"}} frameborder="0" allow="autoplay" allowfullscreen > </iframe></div>
       <div><iframe title="chat" src="https://chat.onestream.live/embed?token=dW5pdmVyc2FsLWNoYXQtMjA1MjEy" style={{height:'500px'}}  frameborder="0" allow="autoplay" allowfullscreen ></iframe></div>
        </div>


      </div>
      <Footer />
      
      </div>
    );


}

export default Live
