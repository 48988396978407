import React, { useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore";
import {db} from '../../../firebase';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Popup from 'reactjs-popup';

import TopNav from "./TopNav";
import Footer from "./Footer";

import data from "./playlist.json";

import Instagram from '../../../assets/images/instagram.png';
import Facebook from '../../../assets/images/facebook.png';
import Whatsapp from '../../../assets/images/whatsapp.png';
import Telegram from '../../../assets/images/telegram.png';
import "./core.css";


const Home = () => {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const [open, setOpen] = useState(false);  
  const closeModal = () => setOpen(false);

  const [liveStatus, setLiveStatus] = useState();

  // console.log("this is live status ", liveStatus)
 
   const fetchLive = async () => {
        
     await getDocs(collection(db, "Controlroom"))
         .then((querySnapshot)=>{               
             const newData = querySnapshot.docs
                 .map((doc) => ({...doc.data(), id:doc.id }));
             setLiveStatus(newData[0].MainStageLive);
         })
    
 }
 
 useEffect(()=>{
     fetchLive();
 }, [])

  return (
    <div>
        <TopNav />
        
        <div className="middle-content extra-top">
        <div>
        <p style={{fontSize:'28px', marginTop:'35px', textAlign:'center'}}>Artists & Creators Promotional Platform <span style={{fontSize:'22px', color:'#00d2ff'}}>* THE STATION TV IS NOW ON ROKU *</span> </p>
        
        <div className="submit"><a href="/joinGeneral" alt="The Station TV Submit Media" style={{color:'white'}}>Artists & Creators Submit Media For Free - Become A Member</a></div>


          <div style={{backgroundColor:'#161616'}}>
          <ul style={{display:'flex', justifyContent:'center'}}>
          {/* { !liveStatus === true ?
        (
       <li>                
      <button type="button" onClick={() => setOpen(o => !o)} style={{backgroundColor:'#333', padding:'9px', marginLeft:'25px', marginRight:'25px', borderRadius:'5px', color:"white", marginTop:'16px', border:'0'}}>WATCH LIVE </button>
     </li> 
        ):(
  
       <li style={{padding:'25px'}}>                
       <a href="/live" alt="Main streaming stage" style={{backgroundColor:'red', padding:'10px', borderRadius:'5px', color:"white"}}>WATCH LIVE </a>
     </li>
        )} */}
  
     {/* <li style={{padding:'25px'}}>                
    <a href="/mainstage" alt="Main streaming stage" style={{backgroundColor:'#002fff', padding:'10px', borderRadius:'5px', color:"white"}}> WATCH</a>
     </li>

     <li style={{padding:'25px'}}>                
    <a href="/audiocast" alt="Main streaming stage" style={{backgroundColor:'#002fff', padding:'10px', borderRadius:'5px', color:"white"}}> LISTEN</a>
     </li> */}

    
     
         </ul>

          </div>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
              <div className="tip-modal-big">
                <a href="" className="close" onClick={closeModal}> &times; </a>
            
                <div className="content">Sorry, we are not currently live. The button will change to <span className="text-red-500">red</span> when we are ready or in the process of going live.<br/><br/>Upcoming Live Streams will be announced via our social media pages. Please join us on social media to find out about when we are planning a live event.</div>
         
                <div className="flex justify-center">
         
                <div className="flex justify-center mt-2">
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://www.instagram.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Instagram} alt="The Station TV Instagram" title="The Station TV Instagram" className="icons" /><span className="icons-name">Instagram</span></a>
            </span>
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://www.facebook.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Facebook} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Facebook</span></a>
            </span>
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://wa.me/17145972178" alt="the station tv message us" target="new"><img src={Whatsapp} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Whatsapp</span></a>
            </span>
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://t.me/+6EW5A2HM378yNTlh" alt="the station tv message us" target="new"><img src={Telegram} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Telegram</span></a>
            </span>
          
          </div>
               </div>
               </div>
          </Popup>
          
          
          
          </div>
          
       



        <div style={{marginTop:'25px', paddingBottom:'25px'}}>
          <div style={{fontSize:'18px', paddingBottom:'10px', textTransform:'uppercase', fontWeight:'bold'}}>Featured Artists</div>
                    <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                  >
                  {data.map((video) => (video.category === "artist" && video.region === "featured") && ( 
                          
                          <div key={video.id} className="artist-info-box">
                          <img src={video.thumbnail} alt='video artist on the station tv' className="thumbnails"></img>
                          <div className="info-container">
                            
                            <div className="artist-info-container">
                                <div className="artist-info"><b>{video.artistsName}</b></div>
                                <div className="artist-info"> {video.location}</div>
                                <div className="artist-info">{video.socialTag }</div>
                            </div> 

                            <div className="watch-button-container"><a href={`/details/${video.id}`} className="watch-button">watch</a>
                            </div>   
                  
                            </div>
                          </div>
                              ))}
                          </Carousel>
            </div>
           
          
      <div style={{marginTop:'55px'}}>
        <div style={{fontSize:'18px', paddingBottom:'10px', textTransform:'uppercase', fontWeight:'bold'}}>The Sydney Celeste Show</div>        

                      <Carousel
                      swipeable={false}
                      draggable={false}
                      showDots={false}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      infinite={true}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      dotListClass="custom-dot-list-style"
                      >
                      {data.map((video) => video.category === "series" && (
                      
                      <div key={video.id} className="artist-info-box">
                      <img src={video.thumbnail} alt='video artist on the station tv' className="thumbnails"></img>
                      <div className="info-container">
                        
                        <div className="artist-info-container">
                            <div className="artist-info"><b>{video.artistsName}</b></div>
                            <div className="artist-info"> {video.location}</div>
                            <div className="artist-info">{video.socialTag }</div>
                        </div> 

                        <div className="watch-button-container"><a href={`/details/${video.id}`} className="watch-button">watch</a>
                        </div>   

                        </div>
                      </div>

                              ))}       

                      </Carousel> 

</div>

<div style={{marginTop:'55px', paddingBottom:'25px'}}>
              <div style={{fontSize:'18px', paddingBottom:'10px', textTransform:'uppercase', fontWeight:'bold'}}>Blast From The Past</div>      

                  <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  dotListClass="custom-dot-list-style"
                >
                {data.map((video) => (video.category === "artist" && video.region === "past") && ( 
                        
                        <div key={video.id} className="artist-info-box">
                        <img src={video.thumbnail} alt='video artist on the station tv' className="thumbnails"></img>
                        <div className="info-container">
                          
                          <div className="artist-info-container">
                              <div className="artist-info"><b>{video.artistsName}</b></div>
                              <div className="artist-info"> {video.location}</div>
                              <div className="artist-info">{video.socialTag }</div>
                          </div> 

                          <div className="watch-button-container"><a href={`/details/${video.id}`} className="watch-button">watch</a>
                          </div>   
                
                          </div>
                        </div>
                          ))}  
                        </Carousel>
                        
                        </div>


<div style={{marginTop:'55px'}}>
        <div style={{fontSize:'18px', paddingBottom:'10px', textTransform:'uppercase', fontWeight:'bold'}}>Original Shows In Production</div>

        <div>
          <p>For more information on release dates please join our socials below or sign up for the newsletter</p></div>        
{/* 
                      <Carousel
                      swipeable={false}
                      draggable={false}
                      showDots={false}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      infinite={true}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      dotListClass="custom-dot-list-style"
                      >
                      {data.map((video) => video.category === "listenlocal" && (
                      
                      <div key={video.id} className="artist-info-box">
                      <img src={video.thumbnail} alt='video artist on the station tv' className="thumbnails"></img>
                      <div className="info-container">
                        
                        <div className="artist-info-container">
                            <div className="artist-info"><b>{video.artistName}</b></div>
                            <div className="artist-info"> {video.location}</div>
                            <div className="artist-info">{video.socialTag }</div>
                        </div> 

                        <div className="watch-button-container"><a href={`/details/${video.id}`} className="watch-button">watch</a>
                        </div>   

                        </div>
                      </div>

                              ))}       

                      </Carousel>  */}

</div>


<div style={{textAlign:'center', backgroundColor:'#222222', padding:'25px', borderRadius:'8px', marginTop:'45px'}}>
<h4 style={{backgroundColor:'#222222'}} className="text-center uppercase mt-6">To contact us or find out who is going to be live join our socials.</h4>
          
          <div className="flex justify-center mt-2">
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://www.instagram.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Instagram} alt="The Station TV Instagram" title="The Station TV Instagram" className="icons" /><span className="icons-name">Instagram</span></a>
            </span>
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://www.facebook.com/thestation.tv/" alt="the station tv message us" target="new"><img src={Facebook} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Facebook</span></a>
            </span>
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://wa.me/17145972178" alt="the station tv message us" target="new"><img src={Whatsapp} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Whatsapp</span></a>
            </span>
            <span className="text-white rounded-lg h-12 p-2 mr-3">
            <a href="https://t.me/+6EW5A2HM378yNTlh" alt="the station tv message us" target="new"><img src={Telegram} alt="The Station TV Facebook" title="The Station TV Facebook" className="icons" /><span className="icons-name">Telegram</span></a>
            </span>
          </div>
          </div>

      </div>

        <Footer />
    </div>
  )
}

export default Home